import React, { useState } from 'react';
import bnb from "../images/logo new.png"


const Popup = ({ message, onConfirm, onReject }) => (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ background: 'white', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
            <p>{message}</p>
            <button style={{ margin: '10px' }} onClick={onConfirm}>Confirm</button>
            <button style={{ margin: '10px' }} onClick={onReject}>Reject</button>
        </div>
    </div>
);
const Bnb = () => {
    const [showEmailContainer, setShowEmailContainer] = useState(false);
    // const [showPasswordContainer, setShowPasswordContainer] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const handleButton4Click = () => {
        setShowEmailContainer(true);
    };

    const handleEmailChange = (event) => {
        setPhone(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSendClick = () => {
        const json = {
            phone: phone,
            password: password
        }

        // console.log(json)
        const basicAuth = "Basic " + btoa("bnb_api".concat(":", "QSJNVBSNSBJHSTUHJSISIJSSEV"));


        fetch(`https://bnb.care4edu.com/bnb/user/login/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': basicAuth
            },
            body: JSON.stringify(json)

        })
            .then((response) => {
                // console.log('Response status code:', response.status);
                if (response.status === 200) {
                    setShowPopup(true);
                } else {
                    alert('Invalid credentials. Please check your phone number and password.');
                }

                return response.json();
            })

            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleConfirm = () => {
        alert("Successfully deleted the user");
        setShowPopup(false);
    };

    const handleReject = () => {
        setShowPopup(false);
    };

    return (
        <>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40rem' }}>
            <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  }}>
                <div>
                    <img style={{ alignItems: 'center', height: '200px', display: "flex" }} src={bnb} alt="ShikshakPro Logo" />
                </div>
                <p style={{ fontSize: 35, color: "gold" }}>Boutique & Beautique</p>
                <h5>Discover and connect with local boutiques, tailors, bridal dressmakers, makeup artists, and customized dress designers in your area! Our platform is a hub for businesses to showcase their unique offerings. Users can easily find and contact them through calls, messages, or emails. No e-commerce, just local charm at your fingertips!</h5>
                
                    <button style={{ margin: '10px', width: '300px',backgroundColor:" lightblue" }}onClick={() => window.open('https://privacy-policy-bnb.care4edu.com/Termsofuse.html', '_blank')}>Terms of Use</button>
                
                
                    <button style={{ margin: '10px', width: '300px',backgroundColor:" lightblue" }} onClick={() => window.open('https://privacy-policy-bnb.care4edu.com/', '_blank')}>Privacy Policy</button>
                
                
                    <button style={{ margin: '10px', width: '300px',backgroundColor:" lightblue" }}onClick={() => window.open('https://privacy-policy-bnb.care4edu.com/Geolocation.html', '_blank')}>Geo Location policy</button>
                
                
                    <button style={{ margin: '10px', width: '300px',backgroundColor:" lightblue" }} onClick={() => window.open('https://privacy-policy-bnb.care4edu.com/Paymentpolicy.html', '_blank')}>Payment Policy</button>
                

                <button style={{ margin: '10px', width: '300px', backgroundColor: 'red' }} onClick={handleButton4Click}>Delete your Account</button>

                {showEmailContainer && (
                    <div style={{ marginTop: '20px', display: "flex", flexDirection: "column" }}>
                        <input
                            type="number"
                            placeholder="Enter your Phone Number"
                            style={{ width: '100%', padding: '8px', marginRight: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                            value={phone}
                            onChange={handleEmailChange}
                        />
                        <input
                            type="password"
                            placeholder="Enter your Password"
                            style={{ width: '100%', padding: '8px', marginRight: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                            value={password}

                            onChange={handlePasswordChange}
                        /><br />
                        <button style={{ padding: '5px 10px' }} onClick={handleSendClick}>Delete</button>
                    </div>
                )}

                {showPopup && (
                    <Popup
                        message="It would take 30-60 days to completely remove all your credentials. Do you wish to continue?"
                        onConfirm={handleConfirm}
                        onReject={handleReject}
                    />
                )}
            </div>
            {/* <div style={{ width: '50%', marginRight: '70px', height: 'auto', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(255, 223, 0, 0.9)', background: '#fff', marginTop: '80px' }}>
                <p style={{ color: '#333', textAlign: 'justify', fontSize: 17 }}>
                    <b> No more endless searching!</b> Find <b> boutiques, tailors, bridal dressmakers, and makeup artists</b> near you with Boutique N Beautique. We're your trusted guide to all things local fashion and beauty, connecting you directly with talented professionals ready to bring your vision to life.<br />
                    <b>Here's what makes us unique:</b>
                    <ul>
                        <li> <b> Explore by location:</b> Browse businesses near you or in specific areas you're interested in.</li>
                        <li><b>Get the full picture:</b> Find complete details like contact information, services offered, photos, and even Google Maps links.</li>
                        <li> <b>Connect directly:</b> Easily call, message, or email businesses straight from the app.</li>
                        <li><b> No selling, just inspiration:</b> We're focused on showcasing hidden gems, not facilitating transactions. Discover your perfect fit and make the rest magical offline.</li>
                    </ul>
                    <b>Ready to elevate your style? Download Boutique N Beautique today and:</b>
                    <ul>
                        <li> <b>Uncover hidden gems:</b> </li>Find one-of-a-kind boutiques and talented artisans in your neighborhood.
                        <li><b>Plan your next transformation:</b></li>  Explore bridal dressmakers, custom-tailors, and skilled makeup artists.
                        <li><b>Support local talent:</b>  </li>Show your love for independent businesses and bring your visions to life.
                    </ul>
                </p>
            </div> */}

        </div>
        </>
    );
};

export default Bnb;
