import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Compnents/Home/HomePage';
import Appbar from './Compnents/Appbar';
import Services from './Compnents/Services/Services';
import ContactUs from './Compnents/Contact.jsx/ContactUs';
import Bnb from './Compnents/Deleting Account/Bnb';
import Partner from './Compnents/Deleting Account/Partner';


function App() {
  return (
    <Router>
      <Appbar/>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/services' element={<Services />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/bnb'  element={<Bnb/>}/>
        <Route path='/partnerapp'  element={<Partner/>}/>
      </Routes>
    </Router>
  );
}

export default App;
